<template>
  <div>
    <HealthProComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import HealthProComp from '@/components/desktop/vitaeHealthHouse/HealthProComp'

  export default {
    name: 'HealthPro',
    props: ['mobile'],
    components: {
      HealthProComp
    },
  }
</script>
