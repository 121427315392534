<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" no-gutters>
      <h3 v-bind:class="mobile ? 'subTitleMobile' : 'subTitle'">{{ $t('subTitle') }}</h3>
    </v-row>
    <v-row class="firstSection" no-gutters>
      <v-col cols="2">
        <v-img
            v-bind:class="mobile ? '' : 'separator'"
            v-bind:src="mobile ? require('@/assets/picto/separator17.svg') : require('@/assets/picto/separator7.svg')"
            contain
            v-bind:height="mobile ? 2655 : 900"
        />
      </v-col>
      <v-col cols="10">
        <v-row justify="start" no-gutters>
          <v-col cols="12">
            <h3 v-bind:class="mobile ? 'titleSectionsMobile' : 'titleSections'" v-html="$t('title1')"></h3>
          </v-col>
          <v-col cols="12">
            <v-img
                class="imgRight"
                v-if="$i18n.locale === 'fr'"
                contain
                v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile1.png') : require('@/assets/healthPro/healthPro1.png')"
                v-bind:height="mobile ? 646 : 300"
                v-bind:width="mobile ? 298 : ''"
            />
            <v-img
                class="imgRight"
                v-else
                contain
                v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile1-EN.png') : require('@/assets/healthPro/healthPro1-EN.png')"
                v-bind:height="mobile ? 646 : 300"
                v-bind:width="mobile ? 298 : ''"
            />
          </v-col>
        </v-row>
        <v-row justify="start" v-bind:class="mobile ? 'secondRowMobile' : 'secondRow'" no-gutters>
          <v-col cols="12">
            <h3 v-bind:class="mobile ? 'titleSectionsMobile' : 'titleSections'" v-html="$t('title2')"></h3>
          </v-col>
          <v-col cols="12">
            <v-img
                v-if="$i18n.locale === 'fr'"
                v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile2.png') : require('@/assets/healthPro/healthPro2.png')"
                contain
                class="imgRight2"
                v-bind:width="mobile ? 330 : ''"
                v-bind:height="mobile ? 522 : 320"
            />
            <v-img
                v-else
                v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile2-EN.png') : require('@/assets/healthPro/healthPro2-EN.png')"
                contain
                class="imgRight2"
                v-bind:width="mobile ? 330 : ''"
                v-bind:height="mobile ? 522 : 320"
            />
          </v-col>
        </v-row>
        <v-row justify="start" v-bind:class="mobile ? 'secondRowMobile' : 'secondRow'" no-gutters>
          <v-col cols="12">
            <h3 v-bind:class="mobile ? 'titleSectionsMobile' : 'titleSections'" v-html="$t('title3')"></h3>
          </v-col>
        </v-row>
        <div v-if="mobile">
          <v-row justify="start" class="secondRowMobile" no-gutters style="padding-bottom: 40px;">
            <v-col cols="12">
              <h3 class="titleSecondPartMobile" v-html="$t('title4')"></h3>
            </v-col>
            <v-col cols="12" class="colMobile">
              <v-img
                  v-if="$i18n.locale === 'fr'"
                  v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile3.png') : require('@/assets/healthPro/healthPro3.png')"
                  contain
                  width="298"
                  height="146"
              />
              <v-img
                  v-else
                  v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile3-EN.png') : require('@/assets/healthPro/healthPro3-EN.png')"
                  contain
                  width="298"
                  height="146"
              />
            </v-col>
            <v-col cols="12" class="colMobile">
              <h3 class="titleSecondPartMobile" v-html="$t('title5')"></h3>
            </v-col>
            <v-col cols="12" class="colMobile">
              <v-img
                  v-if="$i18n.locale === 'fr'"
                  v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile4.png') : require('@/assets/healthPro/healthPro4.png')"
                  contain
                  width="312"
                  height="950"
              />
              <v-img
                  v-else
                  v-bind:src="mobile ? require('@/assets/healthPro/healthProMobile4-EN.png') : require('@/assets/healthPro/healthPro4-EN.png')"
                  contain
                  width="312"
                  height="950"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div class="secondPart" v-if="!mobile">
      <v-row justify="center" align="center" no-gutters class="marginPart">
        <v-col cols="4">
          <h3 class="titleSecondPart" v-html="$t('title4')"></h3>
        </v-col>
        <v-col cols="8">
          <v-img
              v-if="$i18n.locale === 'fr'"
              :src="require('@/assets/healthPro/healthPro3.png')"
              contain
              class="imgRight3"
              height="250"
          />
          <v-img
              v-else
              :src="require('@/assets/healthPro/healthPro3-EN.png')"
              contain
              class="imgRight3"
              height="250"
          />
        </v-col>
      </v-row>
      <v-row justify="center" align="center" no-gutters class="marginPart">
        <v-col cols="2">
          <h3 class="titleSecondPart" v-html="$t('title5')"></h3>
        </v-col>
        <v-col cols="10">
          <v-img
              v-if="$i18n.locale === 'fr'"
              :src="require('@/assets/healthPro/healthPro4.png')"
              contain
              class="imgRight4"
              height="600"
          />
          <v-img
              v-else
              :src="require('@/assets/healthPro/healthPro4-EN.png')"
              contain
              class="imgRight4"
              height="600"
          />
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="lastSection" no-gutters >
        <v-col cols="12">
          <v-img
              v-if="$i18n.locale === 'fr'"
              :src="require('@/assets/healthPro/healthPro5.png')"
              contain
              class="imgRight4"
              height="600"
          />
          <v-img
              v-else
              :src="require('@/assets/healthPro/healthPro5-EN.png')"
              contain
              class="imgRight4"
              height="600"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HealthProComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Health professionals",
    "subTitle": "Modeling of the medico-active path of the patient in ALD (or MC)",
    "title1" : "1st step <br> Awareness / recommendation",
    "title2" : "2nd step - Consultation / evaluation",
    "title3" : "3rd step <br> Orientation",
    "title4" : "Severe functional limitations <br> Supervised practice",
    "title5" : "Other limitations"
  },
  "fr": {
    "mainTitle": "Professionnels de santé",
    "subTitle": "Modélisation du parcours médico-actif du patient en ALD (ou MC)",
    "title1" : "1er temps <br> Sensibilisation / recommandation",
    "title2" : "2ème temps <br>  Consultation / évaluation",
    "title3" : "3ème temps <br> Orientation",
    "title4" : "Limitations fonctionnelles sévères <br> Pratique supervisée",
    "title5" : "Autres limitations"
  }
}
</i18n>

<style scoped>

.firstSection {
  padding-top: 40px;
}

.subTitleMobile {
  margin-top: -25px;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
  color: #1579A7;
  font-size: 16px;
}

.titleSectionsMobile {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
  font-size: 16px;
}

.secondRowMobile {
  padding-top: 20px;
}

.colMobile {
  margin-top: 20px;
}

@media screen and (max-width: 1500px) {
  .imgRight {
    margin-right: 150px;
    margin-top: 40px;
  }

  .imgRight2 {
    margin-right: 200px;
    margin-top: 20px;
  }

  .imgRight3 {
    margin-right: 350px;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  .imgRight4 {
    margin-top: 20px;
    margin-right: 350px;
    margin-bottom: 40px;
  }
  .secondPart {
    padding-left: 240px;
  }
  .marginPart {
    margin-top: -50px;
  }
  .lastSection {
    margin-top: -120px;
  }
}

@media screen and (min-width: 1500px) {
  .imgRight {
    margin-right: 200px;
    margin-top: 40px;
  }

  .imgRight2 {
    margin-right: 450px;
    margin-top: 20px;
  }

  .imgRight3 {
    margin-right: 375px;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  .imgRight4 {
    margin-top: 20px;
    margin-right: 350px;
    margin-bottom: 40px;
  }
  .secondPart {
    padding-left: 310px;
    padding-right: 200px;
  }
  .lastSection {
    padding-bottom: 40px;
  }
}

.separator {
  margin-left: 150px;
}

.titleSections {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.titleSecondPart {
  color: #808080;
}

.titleSecondPartMobile {
  font-size: 16px;
  color: #808080;
}

.secondRow {
  padding-top: 45px;
}

</style>
